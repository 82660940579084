<template>
  <div class="content-administrator-detail global-content-detail-all">

    <div v-if="ObjEmptyGlobal(oAdminDetail)" class="global-content-detail">
      <div ref="iHeaderDetail">
        <header-detail-component-global :sTextDetail="sTextDetailHeader" :sBackHref="sBackHref"
          :bWithPathOrNameRoute="bWithPathOrNameRoute" />
        <v-breadcrumbs :items="itemsBreadcrumbs" divider="/"></v-breadcrumbs>
      </div>
      <div class="content-information">
        <div v-if="!bModify" class="global-content-img">
          <div class="global-content-backgorund">
            <img class="global-img-src" :src="sImgEdit" />
          </div>
        </div>
        <div v-else class="global-content-img">
          <div v-if="dataImg !== null || this.sImgEdit !== null" class="global-content-backgorund">
            <v-btn @click="editImg" icon class="global-button-edit-item">
              <v-icon size="20px"> mdi-pencil </v-icon>
            </v-btn>
            <img v-if="this.sImgEdit !== null" class="global-img-src" :src="this.sImgEdit" />
            <img v-else class="global-img-src" :src="maskedFile(dataImg)" />
          </div>
          <div v-else class="global-content-backgorund">
            <v-btn @click="$refs.inputUpload.click()" class="global-button-add-img">
              <div>
                <v-icon size="40px"> mdi-image-plus </v-icon>
                <p class="global-text-img-add">Arrastra ó da clic</p>
                <p class="global-text-img-add">para añadir</p>
              </div>
            </v-btn>
          </div>
          <input ref="inputUpload" style="display: none" type="file" size="60" accept=".jpg, .jpeg, .png"
            multiple="false" @change="uploadImg" />
        </div>
        <v-container fluid>
          <v-row>
            <v-col cols="12" class="content-all">
              <v-container v-if="!bModify">
                <v-row class="content-text">
                  <v-col cols="12">
                    <p class="p-title">
                      {{ oAdminDetail.sName + " " + oAdminDetail.sLastname }}
                    </p>
                    <p class="p-occupation">{{ oAdminDetail.sRole }}</p>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="5" xl="4">
                    <div class="content-icon-text">
                      <v-icon class="icon-administrator">mdi-email-outline</v-icon>
                      <p class="p-text">
                        {{ oAdminDetail.sEmail }}
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="3" xl="4">
                    <div class="content-icon-text">
                      <v-icon class="icon-administrator">mdi-phone-outline</v-icon>
                      <p v-if="bUserProfileDetailAudit" class="p-text">
                        {{ oAdminDetail.sFullPhoneNumber }}
                      </p>
                      <p v-else class="p-text">
                        {{ formatPhone(oContactInfo) }}
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="3" xl="4">
                    <div class="content-icon-text">
                      <v-icon class="icon-administrator">mdi-calendar-blank-outline</v-icon>
                      <p v-if="bUserProfileDetailAudit" class="p-text">
                        {{ oAdminDetail.tCreatedAt }}
                      </p>
                      <p v-else class="p-text">
                        {{ oAdminDetail.tCreatedAt }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
              <v-container v-else>
                <v-row class="">
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <!-- :rules="textFieldRules" -->
                    <v-text-field v-model="sNameEdit" label="Nombre" placeholder="Nombre..." class="global-text-field"
                      color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
                      persistent-placeholder outlined maxlength="50" @keyup="validateFormMet()">
                      <template slot="label">
                        <span>Nombre<span class="important-data">*</span></span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field v-model="sLastnameEdit" label="Apellido" placeholder="Apellido..."
                      class="global-text-field" color="var(--primary-color-border-input)"
                      background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="50"
                      @keyup="validateFormMet()">
                      <template slot="label">
                        <span>Apellido<span class="important-data">*</span></span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field v-model="sRoleEdit" label="Ocupación laboral" placeholder="Ocupación laboral..."
                      class="global-text-field" color="var(--primary-color-border-input)"
                      background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="40"
                      @keyup="validateFormMet()">
                      <template slot="label">
                        <span>Ocupación laboral<span class="important-data">*</span></span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field v-model="sEmailEdit" disabled readonly label="Correo electrónico"
                      placeholder="Correo electrónico..." class="global-text-field"
                      color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
                      persistent-placeholder outlined @keyup="validateFormMet()">
                      <template slot="label">
                        <span>Correo electrónico</span>
                        <v-tooltip top content-class="global-tooltip-black">
                          <template v-slot:activator="{ on, attrs }">
                            <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on"> -->
                            <v-icon class="ml-2" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>

                            <!-- </v-btn> -->
                          </template>
                          <span>Campo no modificable.</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="8" lg="8" xl="8">
                    <phone-component-global :bImportantDate="true" @updateCountryCallingCode="updateCountryCallingCode"
                      :sCountryCallingCodeOrigin="sCountryCallingCodeEdit"
                      @updateAreaCallingCode="updateAreaCallingCode" :sAreaCallingCodeOrigin="sAreaCallingCodeEdit"
                      @updatePhoneNumber="updatePhoneNumber" :sPhoneNumberOrigin="sPhoneNumberEdit"
                      @updatePhoneExtension="updatePhoneExtension" :sPhoneExtensionOrigin="sPhoneExtensionEdit" />
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div v-show="bViewPermissionsTable" class="content-table-permission">
        <v-divider class="divider-global mt-9"></v-divider>

        <div dark class="global-content-table contenr-table-permission">
          <p class="text-title-permissions">Permisos administrativos</p>

          <v-data-table 
            v-if="!isMobile" 
            mobile-breakpoint="0" 
            :headers="headers" 
            :disable-sort="true"
            :items="aPermissionEdit" 
            :hide-default-footer="true"
            no-data-text="No hay información disponible para mostrar." :footer-props="{
              'items-per-page-text': 'Filas por página',
              'items-per-page-all-text': 'Todos',
            }" class="global-table">
            <template v-slot:[`item.bShow`]="{ item }">
              <div class="global-content-switch-permissions">
                <v-switch :disabled="!bModify" class="global-switch-permission" v-model="item.bShow"
                  color="var(--primary-color-text-yellow)" hide-details
                  @change="validateFormMet(), validateCheckShow(item)"></v-switch>
              </div>
            </template>
            <template v-slot:[`item.bAdmin`]="{ item }">
              <div class="global-content-switch-permissions">
                <v-switch :disabled="!bModify" class="global-switch-permission" v-model="item.bAdmin"
                  color="var(--primary-color-text-yellow)" hide-details
                  @change="validateFormMet(), validateCheckAdmin(item)"></v-switch>
              </div>
            </template>
          </v-data-table>
          <table-mobile-permissions-component-global 
            v-show="isMobile" 
            :bModify="bModify" 
            :aTable="aPermissionEdit"
            :headers="headers"
            @changePermission="changePermission()" />

        </div>
        <v-divider class="divider-global mt-9"></v-divider>
      </div>
      <v-container v-if="bViewPermissionsDetailAudit" fluid>
        <v-divider class="divider-global mb-9"></v-divider>

        <v-row>
          <v-col cols="12" sm="6" md="3" lg="3" xl="3">
            <div>
              <p class="text-title">Nombre del módulo</p>
              <p class="text-information-audit">
                {{ oAdminDetail.oModule.sName }}
                <!-- {{ oManagerInformationOrigin.sFullName }} -->
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="3" xl="3">
            <div>
              <p class="text-title">Fecha y hora de creación</p>
              <p class="text-information-audit">
                {{ oAdminDetail.tCreatedAt }}
                <!-- 30 dic 2022 12:00 PM -->
                <!-- {{ oManagerInformationOrigin.sEmail }} -->
              </p>
            </div>
          </v-col>

          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <div>
              <p class="text-title">Acción registrada</p>
              <p class="text-information-audit">
                {{ oAdminDetail.sDescription }}
                <!-- {{ oManagerInformationOrigin.sEmail }} -->
              </p>
            </div>
          </v-col>

        </v-row>
      </v-container>

      <div class="content-btns-accions">
        <div class="content-btn-second" v-show="bAdminPermission">
          <v-btn v-if="bModify" class="global-btn-second-red" @click="deleteItem(oAdminDetail)">
            Eliminar
          </v-btn>
        </div>

        <v-spacer></v-spacer>
        <div v-show="bAdminPermission" class="content-btn-second">
          <v-btn v-if="bModify" v-show="!bEdit" class="global-btn-neutral" @click="discardChange()">
            Descartar cambios
          </v-btn>
        </div>
        <div v-show="bAdminPermission" class="content-btn-primary">
          <v-btn v-show="bAdminPermission" v-if="!bModify" class="global-btn-primary" @click="bModify = !bModify">
            Modificar
          </v-btn>
          <v-btn v-else v-show="!bEdit" @click="changeAdmin()" :loading="bLoading"
            class="global-btn-primary btn-save-change">
            Guardar cambios
          </v-btn>
          <v-btn v-if="bModify" v-show="bEdit" class="global-btn-neutral" @click="bModify = !bModify">
            Cancelar
          </v-btn>
        </div>
      </div>
    </div>
    <skeleton-user-profile-component-global v-else />
    <!-- <footer-component-global /> -->
    <delete-component-global @setDialogDelete="deleteItem" :oDialogDelete="oDialogDelete"
      :bDialogDelete="bDialogDelete" />
  </div>
</template>

<script>
export default {
  name: "AdministratorsDetail",
  props: {
    sTextDetailHeader: String,
    sBackHref: String,
    bWithPathOrNameRoute: { type: Boolean, default: false },
    bAdminPermission: Boolean,
    itemsBreadcrumbs: Array,
    bViewPermissionsTable: { type: Boolean, default: true },
    bViewPermissionsDetailAudit: { type: Boolean, default: false },
    sApiUserProfile: String
  },
  data() {
    return {
      screenHeight: 0,
      heightMax: 0,
      bLoading: false,
      dataImg: null,
      bModify: false,
      bEdit: true,
      textFieldRules: [(v) => !!v.trim()],
      sImgEdit: [],
      sImgOrigin: [],
      sNameEdit: "",
      sLastnameEdit: "",
      sEmailEdit: "",
      sRoleEdit: "",
      tCreatedAtEdit: "",
      sCountryCallingCodeEdit: "52",
      sAreaCallingCodeEdit: "",
      sPhoneNumberEdit: "",
      sPhoneExtensionEdit: "",
      aPermission: [],
      aPermissionEdit: [],
      aPermissionById: [],
      aPermissionByIdEdit: [],
      sDateEdit: "",
      oAdminDetail: {},
      oContactInfo: {},
      headers: [
        {
          text: "Módulos",
          sortable: false,
          value: "sModuleName",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Ver",
          sortable: false,
          value: "bShow",
          align: "center",

          class: "global-header-table",
          cellClass: "global-body-table",
        },
        {
          text: "Administrar",
          value: "bAdmin",
          sortable: false,

          align: "center",
          class: "global-header-table",
          cellClass: "global-body-table",
        },
      ],
      desserts: [
        {
          sName: "Proveedores",
          bShow: false,
          bAdmin: true,
        },
        {
          sName: "Materias primas",
          bShow: false,
          bAdmin: true,
        },
        {
          sName: "Administradores",
          bShow: false,
          bAdmin: true,
        },
        {
          sName: "Perfiles de usuario",
          bShow: true,
          bAdmin: false,
        },
      ],
      aMonth: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      bDialogDelete: false,
      oDialogDelete: {
        active: false,
        textTitle: "",
        textDescription: "",
        textQuestion: "",
        api: "",
        returnToView: true,
      },
      isMobile: false,


      // #region USER PROFILE 
      // oUserProfile: {}
      // #endregion USER PROFILE 

    };
  },
  beforeMount() {
    this.$store
      .dispatch("getPermissionsByUserGlobal")
      .then((resp) => {
        if (this.sApiUserProfile !== undefined) {
          this.getUserProfile();
        } else {
          this.getAdministratorDetail();
        }
      })
      .catch((err) => {
        this.Error(err);
      });
  },
  updated() {
    this.matchHeight();
  },
  created() {
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.addEventListener("resize ", this.matchHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    matchHeight() {
      if (this.$refs.iHeaderDetail !== undefined) {
        this.heightMax = this.$refs.iHeaderDetail.clientHeight;
        this.heightMax = this.heightMax + 123;
      }
      if (window.innerWidth > 599.99) {
        this.isMobile = false;
        this.screenHeight = window.innerHeight - 100;
      } else {
        this.isMobile = true;
        this.screenHeight = window.innerHeight - 100;
      }
    },
    handleResize() {
      this.matchHeight();
    },
    getAdministratorDetail() {
      const payload = {},
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };
      // `${URI}/api/sp/v1/users/${this.$route.params.id}`
      DB.get(
        `${URI}/api/sp/v1/users/${this.$route.params.idUser}`,
        config,
        payload
      )
        .then((response) => {
          this.oAdminDetail = response.data.results;

          this.oContactInfo = response.data.results.oContactInfo;
          this.$store.commit("setsNameDetail", "");

          this.sNameEdit = this.oAdminDetail.sName;

          this.sLastnameEdit = this.oAdminDetail.sLastname;

          this.sEmailEdit = this.oAdminDetail.sEmail;

          this.sRoleEdit = this.oAdminDetail.sRole;

          this.tCreatedAtEdit = this.oAdminDetail.tCreatedAt;

          this.sCountryCallingCodeEdit = this.oContactInfo.sCountryCallingCode;

          this.sAreaCallingCodeEdit = this.oContactInfo.sAreaCallingCode;

          this.sPhoneNumberEdit = this.oContactInfo.sPhoneNumber;

          this.sPhoneExtensionEdit = this.oContactInfo.sPhoneExtension;

          this.sImgEdit = this.oAdminDetail.sUrl.length > 1
            ? this.oAdminDetail.sUrl[2].sUrl
            : require("@/assets/Empty_Profile.png");
          this.sImgOrigin = this.oAdminDetail.sUrl.length > 1
            ? this.oAdminDetail.sUrl[2].sUrl
            : require("@/assets/Empty_Profile.png");

          this.aPermission = response.data.results.aUserPermissions.map((e) => {
            return {
              sModuleId: e.sModuleId,
              sModuleName: e.sModuleName,
              bShow: e.aPermissions[0].bBoolean,
              sShowPermissionId: e.aPermissions[0].sPermissionId,
              bAdmin: e.aPermissions[1].bBoolean,
              sAdminPermissionId: e.aPermissions[1].sPermissionId,
            };
          });
          this.aPermissionEdit = response.data.results.aUserPermissions.map(
            (e) => {
              return {
                sModuleId: e.sModuleId,
                sModuleName: e.sModuleName,
                bShow: e.aPermissions[0].bBoolean,
                sShowPermissionId: e.aPermissions[0].sPermissionId,
                bAdmin: e.aPermissions[1].bBoolean,
                sAdminPermissionId: e.aPermissions[1].sPermissionId,
              };
            }
          );

          this.bEdit = true;

          this.$store.commit("refresher", false);
          this.bModify = false;
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },
    getUserProfile() {
      const payload = {},
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };
      // `${URI}/api/sp/v1/users/${this.$route.params.id}`
      DB.get(
        `${this.sApiUserProfile}${this.$route.params.idUser}`,
        config,
        payload
      )
        .then((response) => {
          this.oAdminDetail = response.data.results;
          this.oAdminDetail["sName"] = this.oAdminDetail.oUser.sName;
          this.oAdminDetail["sLastname"] = "";
          this.oAdminDetail["sRole"] = this.oAdminDetail.oUser.sRole;
          this.oAdminDetail["sEmail"] = "prueba@sofex.com.mx" //this.oAdminDetail.oUser.sFullPhoneNumber;
          this.oAdminDetail["sFullPhoneNumber"] = "+52 (811) 8238-412 extP. 1234" //this.oAdminDetail.oUser.sFullPhoneNumber;
          this.oAdminDetail["tCreatedAtUser"] = "17 Junio 2022, 12:41 pm" //this.oAdminDetail.oUser.tCreatedAt;

          this.sImgEdit = this.oAdminDetail.oUser.aImage.length > 1
            ? this.oAdminDetail.oUser.aImage[2].sUrl
            : require("@/assets/Empty_Profile.png");

          // this.sImgOrigin = this.oAdminDetail.sUrl.length > 1
          //   ? this.oAdminDetail.sUrl[2].sUrl
          //   : require("@/assets/Empty_Profile.png");

          // this.oContactInfo = response.data.results.oContactInfo;
          // this.$store.commit("setsNameDetail", "");

          // this.sNameEdit = this.oAdminDetail.sName;

          // this.sLastnameEdit = this.oAdminDetail.sLastname;

          // this.sEmailEdit = this.oAdminDetail.sEmail;

          // this.sRoleEdit = this.oAdminDetail.sRole;

          // this.tCreatedAtEdit = this.oAdminDetail.tCreatedAt;

          // this.sCountryCallingCodeEdit = this.oContactInfo.sCountryCallingCode;

          // this.sAreaCallingCodeEdit = this.oContactInfo.sAreaCallingCode;

          // this.sPhoneNumberEdit = this.oContactInfo.sPhoneNumber;

          // this.sPhoneExtensionEdit = this.oContactInfo.sPhoneExtension;



          this.$store.commit("refresher", false);
          this.bModify = false;
        })
        .catch((error) => {
          this.Error(error.response.data);
        });

    },
    discardChange() {
      this.getAdministratorDetail();
      this.validateFormMet();
    },
    changeAdmin() {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.bAdminPermission) {
            let module = [];
            for (let index = 0; index < this.aPermissionEdit.length; index++) {
              const element = this.aPermissionEdit[index];
              module.push({
                sModuleId: element.sModuleId,
                aPermissions: [
                  {
                    sPermissionId: element.sShowPermissionId,
                    bBoolean: element.bShow,
                  },
                  {
                    sPermissionId: element.sAdminPermissionId,
                    bBoolean: element.bAdmin,
                  },
                ],
              });
            }
            const payload = {
              sName: this.sNameEdit,
              sLastname: this.sLastnameEdit,
              sRole: this.sRoleEdit,
              sCountryCallingCode: this.sCountryCallingCodeEdit,
              sAreaCallingCode: this.sAreaCallingCodeEdit,
              sPhoneNumber: this.sPhoneNumberEdit,
              sPhoneExtension: this.sPhoneExtensionEdit,
              aUserPermissions: module,
            },
              config = {
                headers: {
                  Authorization: "Bearer " + this.$store.state.sToken,
                  contentType:
                    "application/x-www-form-urlencoded;charset=utf-8",
                },
              };
            DB.put(
              `${URI}/api/sp/v1/users/${this.$route.params.idUser}`,
              payload,
              config
            )
              .then((response) => {
                if (this.dataImg !== null) {
                  this.addImgAdmin(
                    this.$route.params.idUser,
                    response.data.message,
                    response.data.results.sName +
                    " " +
                    response.data.results.sLastname
                  );
                } else {
                  this.bLoading = false;

                  this.$store.commit("refresher", true);
                  this.Success(
                    response.data.message,
                    response.data.results.sName +
                    " " +
                    response.data.results.sLastname
                  );
                }
              })
              .catch((error) => {
                this.bLoading = false;
                this.Error(error.response.data);
              });
          } else {
            this.discardChange();
            this.Error(this.$store.state.oError403);
          }
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    addImgAdmin(id, sMessage, sFullName) {
      let form = new FormData();
      form.append("aPicture", this.dataImg[0]);

      const config = {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
          contentType: "application/x-www-form-urlencoded;charset=utf-8",
        },
      };

      DB.patch(`${URI}/api/sp/v1/users/${id}`, form, config)
        .then((response) => {
          this.bLoading = false;

          this.$store.commit("refresher", true);
          this.Success(sMessage, sFullName);
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },
    uploadImg: function (e) {
      this.sImgEdit = null;
      this.dataImg = null;
      const files = e.target.files;
      this.dataImg = files;
      this.validateFormMet();
    },
    editImg: function () {
      this.$refs.inputUpload.click();
    },
    maskedFile: function (file) {
      const objectURL = URL.createObjectURL(file[0]);
      return objectURL;
    },
    deleteItem(item) {
      if (item) {
        this.oDialogDelete.textTitle = "Eliminar administrador";
        this.oDialogDelete.textDescription =
          "La eliminación de un administrador es una acción irreversible.";
        this.oDialogDelete.textQuestion =
          "Acepto la responsabilidad y deseo proseguir con la eliminación del administrador: " +
          item.sName +
          ".";
        this.oDialogDelete.api = `${URI}/api/sp/v1/users/${this.$route.params.idUser}`;
      }
      this.bDialogDelete = !this.bDialogDelete;
    },
    veifiedPermissions() {
      var bPermission = true;
      if (
        this.aPermissionEdit.filter(
          (e) => e.bShow !== false || e.bAdmin !== false
        ).length > 0
      ) {
        for (let i = 0; i < this.aPermission.length; i++) {
          if (
            this.aPermission[i].bShow !== this.aPermissionEdit[i].bShow ||
            this.aPermission[i].bAdmin !== this.aPermissionEdit[i].bAdmin
          ) {
            return false;
          }
        }
      } else {
        return true;
      }
      return bPermission;
    },
    validateFormMet() {
      this.bEdit =
        this.sImgEdit === this.sImgOrigin &&
        (this.sNameEdit.trim() === "" ||
          this.sNameEdit.trim() == this.oAdminDetail.sName.trim()) &&
        (this.sLastnameEdit.trim() === "" ||
          this.sLastnameEdit.trim() === this.oAdminDetail.sLastname.trim()) &&
        (this.sEmailEdit.trim() === "" ||
          this.sEmailEdit.trim() === this.oAdminDetail.sEmail.trim()) &&
        (this.sRoleEdit.trim() === "" ||
          this.sRoleEdit.trim() === this.oAdminDetail.sRole.trim()) &&
        (this.sCountryCallingCodeEdit.trim() === "" ||
          this.sCountryCallingCodeEdit.trim() ===
          this.oContactInfo.sCountryCallingCode.trim()) &&
        (this.sAreaCallingCodeEdit.trim() === "" ||
          this.sAreaCallingCodeEdit.trim() ===
          this.oContactInfo.sAreaCallingCode.trim()) &&
        (this.sPhoneNumberEdit.trim() === "" ||
          this.sPhoneNumberEdit.trim() ===
          this.oContactInfo.sPhoneNumber.trim()) &&
        this.sPhoneExtensionEdit.trim() ===
        this.oContactInfo.sPhoneExtension.trim() &&
        this.veifiedPermissions();
    },
    formatPhone(oContactInfo) {
      let sCountryCallingCode = oContactInfo.sCountryCallingCode;
      switch (sCountryCallingCode) {
        case "52":
          return (
            "+" +
            oContactInfo.sCountryCallingCode +
            " (" +
            oContactInfo.sAreaCallingCode +
            ") " +
            [
              oContactInfo.sPhoneNumber.slice(0, 4),
              "-",
              oContactInfo.sPhoneNumber.slice(4),
            ].join("") +
            (oContactInfo.sPhoneExtension
              ? " ext. " + oContactInfo.sPhoneExtension + ""
              : "")
          );

        case "1":
          return (
            "+" +
            oContactInfo.sCountryCallingCode +
            " (" +
            oContactInfo.sAreaCallingCode +
            ") " +
            [
              oContactInfo.sPhoneNumber.slice(0, 3),
              "-",
              oContactInfo.sPhoneNumber.slice(3),
            ].join("") +
            (oContactInfo.sPhoneExtension
              ? " ext. " + oContactInfo.sPhoneExtension + ""
              : "")
          );

        default:
          break;
      }
    },

    changePermission() {
      this.validateFormMet();
    },
    //UPDATE DATE PHONE
    updateCountryCallingCode(val) {
      this.sCountryCallingCodeEdit = val;
      this.validateFormMet();
    },
    updateAreaCallingCode(val) {
      this.sAreaCallingCodeEdit = val;
      this.validateFormMet();
    },
    updatePhoneNumber(val) {
      this.sPhoneNumberEdit = val;
      this.validateFormMet();
    },
    updatePhoneExtension(val) {
      this.sPhoneExtensionEdit = val;
      this.validateFormMet();
    },
    validateCheckAdmin(item) {
      if (item.bAdmin == true) {
        item.bShow = true;
        this.validateFormMet();
      }
    },
    validateCheckShow(item) {
      if (item.bShow == false) {
        item.bAdmin = false;
        this.validateFormMet();
      }

    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    bUserProfileDetailAudit() {
      return this.$route.name === "UserProfileDetailAudit"
    }
  },
  watch: {
    refreshTable: function () {
      this.getAdministratorDetail();
    },
    bAdminPermission() {
      this.discardChange();
    },
  },
};
</script>

<style scoped>
/* .content-administrator-detail {
} */
.content-information {
  display: flex;
  margin-bottom: 15px;
  padding: 0px 4px 0px 12px;
}

.content-table-permission {
  padding: 0px 4px 0px 12px;
}

.contenr-table-permission {
  padding: 0px !important;
}

.button-add-img {
  width: 160px !important;
  height: 160px !important;
  background: transparent 0% 0% no-repeat padding-box !important;
  border: 2px dashed var(--primary-color-divider);
  border-radius: 100%;
  opacity: 1;
}

.text-img-add {
  color: var(--primary-color-text) !important;
  font-family: "pop-Semibold";
  font-size: 9px;
  margin-bottom: 0px;
  text-transform: initial;
}

.text-title-permissions {
  text-align: start;
  letter-spacing: 4.8px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 16px;
  /* margin-right: 10px; */
  font-family: "pop-Bold";
  color: var(--primary-color-color-title-information);
}

.p-title {
  color: var(--primary-color-text);
  text-transform: uppercase;
  font-family: "pop-Semibold";
  font-size: 30px;
  margin-bottom: 0px;
}

.p-occupation {
  color: var(--primary-color-text);
  font-family: "pop-Semibold";
  font-size: 14px;
  margin-bottom: 0px;
}

.content-icon-text {
  display: flex;
}

.icon-administrator {
  color: var(--primary-color-text) !important;
  margin-right: 15px;
}

.p-text {
  color: var(--primary-color-text);
  font-family: "pop-Regular";
  font-size: 14px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

.content-all {
  align-self: center !important;
}

.content-text-field {
  margin-top: 1px;
  margin-left: 10px;
}

.text-title {
  margin-bottom: 2px;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  opacity: 1;
  letter-spacing: 0px;
}

.text-information-audit {
  margin-bottom: 5px;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
}

.content-btns-accions {
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;

  width: 100% !important;
  padding: 0px 4px 0px 12px;
}

.content-btn-primary {
  width: 200px;
}

.content-btn-second {
  width: 200px;
  margin-right: 15px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-information {
    display: block;
  }

  .img-administrator {
    height: 150px;
    width: 160px;
    object-fit: cover;
    /* background-size: cover; */
    border-radius: 100%;
  }

  .p-title {
    color: var(--primary-color-text);
    text-transform: uppercase;
    font-family: "pop-Semibold";
    font-size: 30px;
    text-align: center;
    margin-bottom: 0px;
  }

  .p-occupation {
    color: var(--primary-color-text);
    font-family: "pop-Semibold";
    font-size: 14px;
    text-align: center;
    margin-bottom: 0px;
  }

  .content-icon-text {
    justify-content: center;
  }

  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .content-btn-primary {
    width: 100%;
  }

  .text-img-add {
    color: var(--primary-color-text) !important;
    font-family: "pop-Semibold";
    font-size: 6px;
    margin-bottom: 0px;
    text-transform: initial;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-text-field {
    margin-top: 1px;
    margin-left: 10px;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
